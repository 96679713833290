import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Collapse,
  Dropdown,
} from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { InfoCircle, ChevronDown, ChevronUp } from "react-bootstrap-icons";

import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, functions } from "../firebase-config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import SetupForm from "./SetupForm";
import CreatePledgeModal from "../components/CreatePledgeModal";
import Header from "../components/Header";
import AuthModal from "../components/AuthModal";

import "./Listing.css";
import EndListingModal from "../components/EndListingModal";
import CancelListingModal from "../components/CancelListingModal";
import CreatePurchaseModal from "../components/CreatePurchaseModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//testkey
// const stripePromise = loadStripe(
//   "pk_test_51L5XbWK9bVxqJvnKcEEVfqEfi5IMlZmYFtk1prOtOsmFhRGUG1J2zwGTQcaUwHoAe6roVxYXc8amKMDPSfsAqcjF00ZId8zHQA"
// );

//real key
const stripePromise = loadStripe(
  "pk_live_51L5XbWK9bVxqJvnKTIbRUrj0FP9livN6evTWDajr2QxzaS0SFilZeFGfs2ygYRDKqOjch38XozJajZATetR4lN0s00Wp2hUqoF"
);

function Product() {
  const { productID } = useParams();

  //authmodal
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [clientSecret, setClientSecret] = useState(null);
  // const [posts, setPosts] = useState([]);
  const [post, setPost] = useState(null);
  const [images, setImages] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showCreatePledgeModal, setShowCreatePledgeModal] = useState(false);
  const [email, setEmail] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [user, setUser] = useState({});

  const [customers, setCustomers] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showEndListingModal, setShowEndListingModal] = useState(false);
  const [showCancelListingModal, setShowCancelListingModal] = useState(false);
  const [daysSincePost, setDaysSincePost] = useState(null);

  const [showInfoAboutPledging, setShowInfoAboutPledging] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeError, setSizeError] = useState(false);

  const getDashboard = async () => {
    // console.log("userid", user.uid);
    console.log("dashy");
    //get pledges associated with the listing
    //setShowDasbhboard to true

    setShowDashboard(true);

    // const customersCollectionRef = collection(db, "customers");

    // const q2 = query(
    //   customersCollectionRef,
    //   where("backedProject", "==", productID),
    //   orderBy("createdAt", "asc"),
    //   limit(100)
    // );

    // const data = await getDocs(q2);
    // setCustomers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    // console.log("customers", data.docs);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log("user");
      console.log("user", currentUser);
      // console.log("uid", currentUser.uid);
      if (currentUser === null) {
        setShowDashboard(false);
      }
    });
  }, []);

  //   useEffect(() => {
  //     if (post) {
  //       // console.log("serverts", serverTimestamp());
  //       // console.log("createdat", post.createdAt);
  //       const difference = Math.floor(
  //         (Date.now() - post.createdAt.seconds * 1000) / (60 * 60 * 24 * 1000)
  //       );
  //       //this will be in milliseconds
  //       // console.log("diff", difference);
  //       setDaysSincePost(difference);
  //     }
  //   }, [post]);

  useEffect(() => {
    if (user) {
      if (user.uid === post?.userID && user.uid !== undefined) {
        // console.log("usyuid", user.uid);
        // console.log("postusyid", post.userID);
        getDashboard();
      }
    }
  }, [user]);

  useEffect(() => {
    // if (user?.uid !== undefined && user.uid === post.userID) {
    if (user?.uid !== undefined && user.uid === post.userID) {
      // console.log("hola");
      getDashboard();
    }
  }, [post]);
  //get doc based on listingID
  useEffect(() => {
    console.log("proddyid", productID);
    const getPost = async () => {
      const docRef = doc(db, "fluorite", productID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setPost(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getPost();
  }, [productID]);
  //return error if no such doc --dont need this for MVP though

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const createStripePayment = () => {
    console.log("created");
    setLoading(true);

    const createPayment = httpsCallable(functions, "createPayment");
    createPayment({ amount: 5500 }).then((result) => {
      console.log("result", result);

      setClientSecret(result.data.clientSecret);

      //   setClientSecret(result.data.setty.client_secret);
      //   setCustomerID(result.data.setty.customer);
      //   setLoading(false);
    });
  };

  const onBuyNowClick = () => {
    if (selectedSize) {
      setShowCreatePledgeModal(true);
      setSizeError(false);
    } else {
      setSizeError(true);
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      {/* {showCreatePledgeModal && <div>Listing {listingID}</div>} */}
      {/* {user && (
        <div className="signOutDiv">
          <button type="button" class="btn btn-primary" onClick={logout}>
            Sign Out
          </button>
        </div>
      )} */}
      <Header
        user={user}
        onClickSignIn={() => {
          setShowAuthModal(true);
          setShowLogin(true);
        }}
      />
      <div className="listingDiv">
        <AuthModal
          user={user}
          showAuthModal={showAuthModal}
          setShowAuthModal={setShowAuthModal}
          showSignUp={showSignUp}
          setShowSignUp={setShowSignUp}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
        />

        <Container>
          <Row>
            <Col
              className="leftCol"
              // style={{ maxWidth: "50%" }}
              sm={8}
            >
              {/* {images.map((image) => (
                <p>{image}</p>
              ))} */}
              {post && (
                <Carousel className="carousel" swipeable={false}>
                  {post?.images.map((image) => (
                    <div key={image} className="caroDiv">
                      <img className="carouselImage" src={image} />
                    </div>
                  ))}
                </Carousel>
              )}
            </Col>

            {post && (
              <Col sm={4}>
                <h1>{post.title}</h1>

                {/* <div style={{ marginBottom: "15px" }}>
                  Created by @{post.instagram}
                 
                </div> */}
                {/* <div style={{ marginBottom: "15px" }}>
                  Created by @
                  <span className="spanLink" onClick={openInsta}>
                    {post.instagram}
                  </span>
                </div> */}

                <div style={{ marginBottom: "15px" }}>
                  <p>${post.price}</p>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <p>{post.details}</p>
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{ width: "100%" }}
                      variant="secondary"
                      id="dropdown-basic"
                    >
                      {selectedSize ? selectedSize : "Select Size"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {post?.sizes.map((size) => (
                        <Dropdown.Item
                          onClick={() => setSelectedSize(size)}
                          key={size}
                          href="#/action-1"
                        >
                          {size}
                        </Dropdown.Item>
                      ))}
                      {/* <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {sizeError && (
                  <div style={{ marginBottom: "10px", color: "red" }}>
                    Please select a size.
                  </div>
                )}

                <div>
                  {post?.status === "default" && (
                    <Button
                      style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                        marginBottom: "40px",
                      }}
                      variant="success"
                      onClick={onBuyNowClick}
                    >
                      Buy Now
                    </Button>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {showCreatePledgeModal && (
        // <CreatePledgeModal
        //   setShowCreatePledgeModal={setShowCreatePledgeModal}
        //   createStripeCustomer={createStripeCustomer}
        //   setEmail={setEmail}
        //   email={email}
        //   clientSecret={clientSecret}
        //   stripePromise={stripePromise}
        //   options={options}
        //   customerID={customerID}
        //   loading={loading}
        // />
        <CreatePurchaseModal
          setShowCreatePledgeModal={setShowCreatePledgeModal}
          //   createStripeCustomer={createStripeCustomer}
          createStripePayment={createStripePayment}
          setEmail={setEmail}
          email={email}
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          options={options}
          customerID={customerID}
          loading={loading}
          product={post.title}
          size={selectedSize}
        />
      )}
    </div>
  );
}

export default Product;
