import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/Home";
import Listing from "./pages/Listing";
import Profile from "./pages/Profile";
import PledgeConfirmed from "./pages/PledgeConfirmed";
import ListingEndedConfirmed from "./pages/ListingEndedConfirmed";
import ListingCancelledConfirmed from "./pages/ListingCancelledConfirmed";
import Footer from "./components/Footer";
import PledgeScreen from "./components/PledgeScreen";
import JadeNy from "./pages/JadeNy";
import Product from "./pages/Product";
import PurchaseConfirmed from "./pages/PurchaseConfirmed";
import NewStuff from "./pages/NewStuff";

function App() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Home />} />
    //     <Route path="/profile" element={<Profile />} />
    //     <Route path="/listing/:listingID" element={<Listing />} />
    //     <Route path="/pledgeconfirmed" element={<PledgeConfirmed />} />
    //     <Route path="/listingended" element={<ListingEndedConfirmed />} />
    //     <Route
    //       path="/listingcancelled"
    //       element={<ListingCancelledConfirmed />}
    //     />

    //     <Route path="/jadeny" element={<JadeNy />} />
    //     <Route path="/product/:productID" element={<Product />} />
    //     <Route path="/purchaseconfirmed" element={<PurchaseConfirmed />} />
    //   </Routes>

    //   <Footer />
    // </Router>
    <NewStuff />
  );
}

export default App;
