import React from "react";

export default function NewStuff() {
  return (
    <div
      style={{
        color: "gray",
        // maxWidth: "200px",
        // margin: "auto",
        // paddingTop: "50",
        // marginLeft: "50vw",
        paddingTop: "40vh",
        // paddingLeft: "50vw",
        // backgroundColor: "red",
        textAlign: "center",
      }}
    >
      Something new is coming...
    </div>
  );
}
