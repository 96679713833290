import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useParams } from "react-router-dom";
import { collection, serverTimestamp, addDoc } from "firebase/firestore";

import { db } from "../firebase-config";
import { Button, Spinner } from "react-bootstrap";
import SetupForm from "../pages/SetupForm";
import CreatePledgeModal from "./CreatePledgeModal";

const stripePromise = loadStripe(
  "pk_test_51L5XbWK9bVxqJvnKcEEVfqEfi5IMlZmYFtk1prOtOsmFhRGUG1J2zwGTQcaUwHoAe6roVxYXc8amKMDPSfsAqcjF00ZId8zHQA"
);

const PledgeScreen = (
  {
    // email, customerID
  }
) => {
  const email = "yo@gmail.com";
  const customerID = "cus_Lp2YEOpI30gH7W";

  const options = {
    // passing the client secret obtained in step 2
    clientSecret:
      "seti_1L7UgTK9bVxqJvnK2JszEq4Y_secret_Lp8yDpnqrbg26pIKdDNl9Eb8BRNBbMK",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const { listingID } = useParams();

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [getPaymentStatus, setGetPaymentStatus] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <CreatePledgeModal />
    </div>
  );
};

export default PledgeScreen;
