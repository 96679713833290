import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Badge,
  Collapse,
  Spinner,
} from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { InfoCircle, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { FaShippingFast } from "react-icons/fa";

import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, functions } from "../firebase-config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import SetupForm from "./SetupForm";
import CreatePledgeModal from "../components/CreatePledgeModal";
import Header from "../components/Header";
import AuthModal from "../components/AuthModal";

import "./Listing.css";
import EndListingModal from "../components/EndListingModal";
import CancelListingModal from "../components/CancelListingModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//testkey
// const stripePromise = loadStripe(
//   "pk_test_51L5XbWK9bVxqJvnKcEEVfqEfi5IMlZmYFtk1prOtOsmFhRGUG1J2zwGTQcaUwHoAe6roVxYXc8amKMDPSfsAqcjF00ZId8zHQA"
// );

//real key
const stripePromise = loadStripe(
  "pk_live_51L5XbWK9bVxqJvnKTIbRUrj0FP9livN6evTWDajr2QxzaS0SFilZeFGfs2ygYRDKqOjch38XozJajZATetR4lN0s00Wp2hUqoF"
);

function Listing() {
  const { listingID } = useParams();

  //authmodal
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [clientSecret, setClientSecret] = useState(null);
  // const [posts, setPosts] = useState([]);
  const [post, setPost] = useState(null);
  const [images, setImages] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showCreatePledgeModal, setShowCreatePledgeModal] = useState(false);
  const [email, setEmail] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [user, setUser] = useState({});

  const [customers, setCustomers] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showEndListingModal, setShowEndListingModal] = useState(false);
  const [showCancelListingModal, setShowCancelListingModal] = useState(false);
  const [daysSincePost, setDaysSincePost] = useState(null);

  const [showInfoAboutPledging, setShowInfoAboutPledging] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDashboard = async () => {
    // console.log("userid", user.uid);
    console.log("dashy");
    //get pledges associated with the listing
    //setShowDasbhboard to true

    setShowDashboard(true);

    const customersCollectionRef = collection(db, "customers");

    const q2 = query(
      customersCollectionRef,
      where("backedProject", "==", listingID),
      orderBy("createdAt", "asc"),
      limit(100)
    );

    const data = await getDocs(q2);
    setCustomers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log("customers", data.docs);

    // const unsubscribe = onSnapshot(q2, (querySnapshot) => {
    //   const customersArray = [];
    //   querySnapshot.forEach((doc) => {
    //     customersArray.push({ ...doc.data(), id: doc.id });
    //   });
    //   console.log("new docs", customersArray);
    //   setCustomers(customersArray);
    // });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // console.log("user");
      console.log("user", currentUser);
      // console.log("uid", currentUser.uid);
      if (currentUser === null) {
        setShowDashboard(false);
      }
      // else {
      //   console.log("useriddddd", currentUser.uid);
      //   console.log("postiidddd", post);
      // }
      // if (post && currentUser?.uid === post.userID) {
      //   getDashboard();
      // }
    });
  }, []);

  useEffect(() => {
    if (post) {
      // console.log("serverts", serverTimestamp());
      // console.log("createdat", post.createdAt);
      const difference = Math.floor(
        (Date.now() - post.createdAt.seconds * 1000) / (60 * 60 * 24 * 1000)
      );
      //this will be in milliseconds
      // console.log("diff", difference);
      setDaysSincePost(difference);
    }
  }, [post]);

  useEffect(() => {
    if (user) {
      if (user.uid === post?.userID && user.uid !== undefined) {
        // console.log("usyuid", user.uid);
        // console.log("postusyid", post.userID);
        getDashboard();
      }
    }
  }, [user]);

  useEffect(() => {
    // if (user?.uid !== undefined && user.uid === post.userID) {
    if (user?.uid !== undefined && user.uid === post.userID) {
      // console.log("hola");
      getDashboard();
    }
  }, [post]);
  //get doc based on listingID
  useEffect(() => {
    // const getPost = async () => {
    //   const postsRef = collection(db, "posts");
    //   const q = query(postsRef, where("id", "==", listingID));
    //   const querySnapshot = await getDocs(q);
    //   const postsArray = [];
    //   querySnapshot.forEach((doc) => {
    //     postsArray.push({ ...doc.data(), id: doc.id });
    //     // console.log(doc.id, " => ", doc.data());
    //   });
    //   setPosts(postsArray);

    //   setImages(postsArray[0].images);
    //   // console.log("images", postsArray[0].images[1]);
    // };
    // getPost();

    const getPost = async () => {
      const docRef = doc(db, "posts", listingID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setPost(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getPost();
  }, [listingID]);
  //return error if no such doc --dont need this for MVP though

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const createStripeCustomer = () => {
    console.log("created");
    setLoading(true);

    const createCustomer = httpsCallable(functions, "createCustomer");
    createCustomer({ email: email.trim() }).then((result) => {
      // const data = result.data;
      // console.log("data", data);
      console.log("result", result.data.setty);
      // console.log("result", result.data.setty.client_secret);
      // console.log("result", result.data.bruh);
      setClientSecret(result.data.setty.client_secret);
      setCustomerID(result.data.setty.customer);
      setLoading(false);
    });
  };

  const openInsta = () => {
    window.open(`https://instagram.com/${post.instagram}`);
  };

  return (
    <div>
      {/* {showCreatePledgeModal && <div>Listing {listingID}</div>} */}
      {/* {user && (
        <div className="signOutDiv">
          <button type="button" class="btn btn-primary" onClick={logout}>
            Sign Out
          </button>
        </div>
      )} */}
      <Header
        user={user}
        onClickSignIn={() => {
          setShowAuthModal(true);
          setShowLogin(true);
        }}
      />
      <div className="listingDiv">
        {showEndListingModal && (
          <EndListingModal
            setShowEndListingModal={setShowEndListingModal}
            listingID={listingID}
          />
        )}
        {showCancelListingModal && (
          <CancelListingModal
            setShowCancelListingModal={setShowCancelListingModal}
            listingID={listingID}
          />
        )}
        <AuthModal
          user={user}
          showAuthModal={showAuthModal}
          setShowAuthModal={setShowAuthModal}
          showSignUp={showSignUp}
          setShowSignUp={setShowSignUp}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
        />

        <Container>
          {!post && (
            <div style={{ paddingBottom: "40px", paddingTop: "20px" }}>
              <Spinner
                as="span"
                animation="border"
                // size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginLeft: "50%" }}
              />
            </div>
          )}
          <Row>
            <Col
              className="leftCol"
              // style={{ maxWidth: "50%" }}
              // sm={8}
            >
              {/* {images.map((image) => (
                <p>{image}</p>
              ))} */}
              {post && (
                <Carousel className="carousel" swipeable={false}>
                  {post?.images.map((image) => (
                    <div key={image} className="caroDiv">
                      <img className="carouselImage" src={image} />
                    </div>
                  ))}
                  {/* <div>
                  <img src={images[0]} />
                </div>
                <div>
                  <img src="https://www.freecodecamp.org/news/content/images/2022/03/ss-7.png" />
                </div> */}
                </Carousel>
              )}
            </Col>

            {post && (
              <Col
              // sm={4}
              >
                <h1>{post.title}</h1>
                <div className="badgesRow">
                  {post?.status === "default" && (
                    <Badge
                      bg="warning"
                      style={{ marginBottom: "10px", marginRight: "10px" }}
                    >
                      This listing ends in {30 - daysSincePost} days
                    </Badge>
                  )}
                  {post?.status === "ended" && (
                    <Badge
                      bg="success"
                      style={{ marginBottom: "10px", marginRight: "10px" }}
                    >
                      Successfully Funded
                    </Badge>
                  )}
                  {post.price > post.discountedPrice && (
                    <Badge
                      bg="info"
                      style={{ marginBottom: "10px", marginRight: "10px" }}
                    >
                      {Math.round(
                        ((post.price - post.discountedPrice) / post.price) * 100
                      )}
                      % off Threadfunder discount
                    </Badge>
                  )}
                  {post.shippingType === "free" && (
                    <Badge bg="success" style={{ marginBottom: "10px" }}>
                      Free shipping
                    </Badge>
                  )}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  Created by @{post.instagram}
                  {/* <span className="spanLink" onClick={openInsta}>
                  </span> */}
                </div>
                {/* <div style={{ marginBottom: "15px" }}>
                  Created by @
                  <span className="spanLink" onClick={openInsta}>
                    {post.instagram}
                  </span>
                </div> */}

                <div style={{ marginBottom: "15px" }}>{post.details}</div>
                {post.price > post.discountedPrice && (
                  <div style={{ marginBottom: "0px" }}>
                    <p style={{ marginBottom: "5px", color: "gray" }}>
                      Expected retail price:{" "}
                      <span className="strikeThroughPrice"> ${post.price}</span>
                    </p>
                    <p>Threadfunder pledge price: ${post.discountedPrice}</p>
                  </div>
                )}

                <div style={{ marginBottom: "20px" }}>
                  {post.shippingType === "free" && (
                    <p style={{ marginBottom: "5px", color: "gray" }}>
                      <FaShippingFast
                        size={25}
                        color="gray"
                        style={{ marginRight: "10px" }}
                      />
                      Free Shipping!
                    </p>
                  )}
                  {post.shippingType === "charge" && (
                    <p style={{ marginBottom: "5px", color: "gray" }}>
                      <FaShippingFast
                        size={25}
                        color="gray"
                        style={{ marginRight: "10px" }}
                      />
                      The creator will contact you about shipping costs when the
                      listing ends.
                    </p>
                  )}
                  {post.shippingType !== "free" &&
                    post.shippingType !== "charge" && (
                      <p style={{ marginBottom: "5px", color: "gray" }}>
                        <FaShippingFast
                          size={25}
                          color="gray"
                          style={{ marginRight: "10px" }}
                        />
                        The creator will contact you about shipping costs when
                        the listing ends.
                      </p>
                    )}
                </div>

                <div
                  style={{
                    maxWidth: "100%",
                    // backgroundColor: "lightgray",
                    // overflow: "hidden",
                    // whiteSpace: "nowrap",
                    // paddingRight: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "10px",
                    // border: "lightgray 1px solid",

                    boxShadow: "   0 0 0 1px #d0d0d0",
                    borderRadius: "5px",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      // backgroundColor: "blue",
                      maxWidth: "70%",
                      paddingRight: "10px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span>{`https://threadfunder.com/listing/${listingID}`}</span>
                  </div>
                  <CopyToClipboard
                    text={`https://threadfunder.com/listing/${listingID}`}
                    onCopy={() => setLinkCopied(true)}
                  >
                    {linkCopied ? (
                      <button className="linkCopiedButton">Link Copied!</button>
                    ) : (
                      <button className="copyLinkButton">Copy Link</button>
                    )}
                  </CopyToClipboard>
                </div>

                <div>
                  {post?.status === "default" && (
                    <Button
                      style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                      }}
                      variant="success"
                      onClick={() => setShowCreatePledgeModal(true)}
                    >
                      Pledge ${post.discountedPrice}
                    </Button>
                  )}
                  {post?.status === "hidden" && (
                    <Button
                      style={{
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontWeight: "bold",
                      }}
                      variant="success"
                      onClick={() => setShowCreatePledgeModal(true)}
                    >
                      Pledge ${post.discountedPrice}
                    </Button>
                  )}
                  {post?.status === "ended" && (
                    <Button
                      variant="outline-secondary"
                      disabled
                      style={{ width: "100%" }}
                    >
                      Listing Ended
                    </Button>
                  )}
                  {post?.status === "cancelled" && (
                    <Button
                      variant="outline-secondary"
                      disabled
                      style={{ width: "100%" }}
                    >
                      Listing Cancelled
                    </Button>
                  )}
                  <p
                    onClick={() =>
                      setShowInfoAboutPledging(!showInfoAboutPledging)
                    }
                    className="grayInfoTextHeader"
                  >
                    <InfoCircle style={{ marginRight: "10px" }} /> How pledging
                    works{" "}
                    {showInfoAboutPledging ? <ChevronUp /> : <ChevronDown />}{" "}
                  </p>
                  <Collapse in={showInfoAboutPledging}>
                    <p className="grayInfoText">
                      To create a pledge, you must enter your payment
                      information, but you will not be charged immediately. You
                      will only be charged if the creator decides to convert
                      pledges and move forward with producing the item.
                    </p>
                  </Collapse>
                  {post?.status === "hidden" && (
                    <p className="grayInfoTextHeader">
                      ***This is an unpublished sample listing. Only published
                      listings are displayed on our{" "}
                      <a href="https://threadfunder.com/">home page</a>.***
                    </p>
                  )}
                  {/* {showInfoAboutPledging && (
                  )} */}
                </div>
              </Col>
            )}
          </Row>
          {showDashboard && (
            <Row style={{ padding: "10px" }}>
              <div className="creatorListingDiv">
                <h1>Project Dashboard</h1>
                <p className="grayInfoText">
                  <InfoCircle /> Only you, the project creator, can see the
                  information in this dashboard.
                </p>
                <h2>Project Actions</h2>
                {!post?.listingEndedTime && post?.status !== "cancelled" && (
                  <div>
                    <p>
                      Your listing will be available for 30 days. It will be
                      displayed on Threadfunder's home page, and you can share
                      your listing's link with anyone. Anyone can make a pledge
                      to preorder the product in your listing by entering their
                      email and payment information, but they will not be
                      charged immediately. After 30 days has elapsed, you will
                      be given the option to either{" "}
                      <span className="boldTx ">
                        {" "}
                        (1) end your listing and collect pledges
                      </span>
                      , or{" "}
                      <span className="boldTx ">
                        {" "}
                        (2) cancel your listing.{" "}
                      </span>{" "}
                      However, you can also do these two things{" "}
                      <span className="boldTx ">
                        any time before the 30 days is up.{" "}
                      </span>
                    </p>

                    <p>
                      1.{" "}
                      <span className="boldTx ">
                        {" "}
                        Ending your listing and collecting pledges:{" "}
                      </span>{" "}
                      You can end your listing and collect pledges when 30 days
                      has elapsed, OR any time before. In this case, pledgers{" "}
                      <span className="boldTx ">will be charged,</span> and you
                      will receive payment via your choice of either Paypal,
                      Venmo, or Zelle. (You can also contact
                      threadfunder@gmail.com for additional payout options). If
                      you collect pledges, you will be expected to follow
                      through with production in a timely manner, and to gather
                      sizing and shipping information from pledgers via their
                      emails, which are provided in the table below.
                    </p>
                    <p>
                      2.{" "}
                      <span className="boldTx ">
                        {" "}
                        Cancelling your listing:{" "}
                      </span>{" "}
                      You can cancel your listing when 30 days has elapsed, OR
                      any time before. In this case, pledgers{" "}
                      <span className="boldTx ">will NOT be charged</span> and
                      you will not receive any payments. Cancelling simply
                      prevents further pledging to the project and removes the
                      listing from Threadfunder's home page.
                    </p>
                    <p>
                      Note: If you decide to collect pledges, there is a{" "}
                      <a
                        style={{ color: "inherit" }}
                        href="https://stripe.com/pricing"
                      >
                        {" "}
                        2.9% + $0.30 processing fee
                      </a>{" "}
                      charged for each pledge by Stripe, our payment processor.
                      But other than that, Threadfunder is completely free to
                      use. Threadfunder does not charge anything because this
                      website was just made for fun :)
                    </p>
                  </div>
                )}

                {!post?.listingEndedTime && post?.status !== "cancelled" && (
                  <Row>
                    <Button
                      style={{
                        marginBottom: "10px",
                        maxWidth: "750px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="success"
                      onClick={() => setShowEndListingModal(true)}
                    >
                      End Listing and Collect Pledges
                    </Button>
                    <Button
                      style={{
                        marginBottom: "10px",
                        maxWidth: "750px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "10px",
                      }}
                      variant="danger"
                      onClick={() => setShowCancelListingModal(true)}
                    >
                      Cancel Listing and DON'T Collect Pledges
                    </Button>
                  </Row>
                )}
                {post?.listingEndedTime && (
                  <div className="greenTx boldTx">
                    Your listing was ended successfully and your payment is
                    processing! Pledgers will be charged and you will be
                    notified via email when your payment has been sent. Feel
                    free to email threadfunder@gmail.com if you have any
                    questions!
                  </div>
                )}
                {post?.status === "cancelled" && (
                  <div className="grayTx boldTx">
                    Your listing was cancelled successfully. People can no
                    longer pledge to the project and this listing has been
                    removed from Thread Funder's home page.
                  </div>
                )}

                <h2>
                  Pledges{" "}
                  {customers.length > 0 ? "(" + customers.length + ")" : ""}
                </h2>
                {!(customers.length > 0) && (
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      This project has not received any pledges yet. Try sharing
                      this project's link with people to start gathering
                      pledges!
                    </div>
                    <div
                      style={{
                        maxWidth: "100%",
                        // backgroundColor: "lightgray",
                        // overflow: "hidden",
                        // whiteSpace: "nowrap",
                        // paddingRight: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "10px",
                        // border: "lightgray 1px solid",

                        boxShadow: "   0 0 0 1px #d0d0d0",
                        borderRadius: "5px",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "blue",
                          maxWidth: "70%",
                          paddingRight: "10px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{`https://threadfunder.com/listing/${listingID}`}</span>
                      </div>
                      <CopyToClipboard
                        text={`https://threadfunder.com/listing/${listingID}`}
                        onCopy={() => setLinkCopied(true)}
                      >
                        {linkCopied ? (
                          <button className="linkCopiedButton">
                            Link Copied!
                          </button>
                        ) : (
                          <button className="copyLinkButton">Copy Link</button>
                        )}
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
                {customers.length > 0 && (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Email Address</th>
                          <th>Pledge Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((customer, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{customer.email}</td>
                            <td>
                              {customer.createdAt.toDate().toDateString()}
                            </td>
                          </tr>
                        ))}
                        {/* <tr>
                      <td>2</td>
                      <td>Jacob</td>
                      <td>Thornton</td>
                    </tr> */}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            </Row>
          )}
        </Container>
      </div>
      {showCreatePledgeModal && (
        <CreatePledgeModal
          setShowCreatePledgeModal={setShowCreatePledgeModal}
          createStripeCustomer={createStripeCustomer}
          setEmail={setEmail}
          email={email}
          clientSecret={clientSecret}
          stripePromise={stripePromise}
          options={options}
          customerID={customerID}
          loading={loading}
        />
      )}
    </div>
  );
}

export default Listing;
