import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPaintBrush, FaHandshake } from "react-icons/fa";
import { FcBinoculars, FcShipped } from "react-icons/fc";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { GiPaintBrush } from "react-icons/gi";

import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";

import { auth, db } from "../firebase-config";
import Header from "../components/Header";
import AuthModal from "../components/AuthModal";
import PostCard from "../components/PostCard";

import "./Home.css";

function Home() {
  const navigate = useNavigate();

  //authmodal
  // const [showAuthModal, setShowAuthModal] = useState(false);
  // const [showSignUp, setShowSignUp] = useState(false);
  // const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState({});
  const [posts, setPosts] = useState([]);
  const [clickedPost, setClickedPost] = useState(null);

  const [filterType, setFilterType] = useState("Discover");
  const [fundedPosts, setFundedPosts] = useState([]);

  const postsCollectionRef = collection(db, "posts");

  useEffect(() => {
    const q = query(
      postsCollectionRef,
      where("status", "==", "default"),
      orderBy("createdAt", "asc"),
      // orderBy("createdAt", "desc"),
      limit(100)
    );
    const getPosts = async () => {
      const data = await getDocs(q);
      setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getPosts();
    console.log("posts");
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log("user");
    });
  }, []);

  useEffect(() => {
    if (clickedPost) {
      console.log("func called");
      // navigate("/pledgeconfirmed");
      // navigate(`/`);
      navigate(`/listing/${clickedPost}`);
      // window.location.replace(`https://threadfunder.com/pledgeconfirmed`);
      // window.open(`https://threadfunder.com/pledgeconfirmed`);
      // window.open(`https://threadfunder.com/listing/${clickedPost}`, "_self");
    }
  }, [clickedPost]);

  const listOwnDesigns = () => {
    // console.log("hi");
    navigate(`/profile`);
  };

  useEffect(() => {
    if (filterType === "Successfully Funded" && fundedPosts.length === 0) {
      // console.log("heloooooorr");

      const q = query(
        postsCollectionRef,
        where("status", "==", "ended"),
        orderBy("createdAt", "asc"),
        // orderBy("createdAt", "desc"),
        limit(100)
      );
      const getPosts = async () => {
        const data = await getDocs(q);
        setFundedPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      };

      getPosts();
    }
  }, [filterType]);

  return (
    <div className="main">
      <Header
        homeScreen={"t"}
        user={user}
        // onClickSignIn={() => {
        //   setShowAuthModal(true);
        //   setShowLogin(true);
        // }}
      />
      {/* <AuthModal
            user={user}
            showAuthModal={showAuthModal}
            setShowAuthModal={setShowAuthModal}
            showSignUp={showSignUp}
            setShowSignUp={setShowSignUp}
            showLogin={showLogin}
            setShowLogin={setShowLogin}
          /> */}
      {/* <Container> */}
      {/* <Row>Fund and create clothing designs!</Row> */}
      {/* <div>Back upcoming brands by pledging to preorder potential designs.</div>
      <div>Help bring a design to life by backing a project!</div> */}
      <div className="topBann">
        {/* Help bring new clothing designs to life! */}

        {/* <h1 className="titley">Help bring new clothing designs to life!</h1> */}
        <h1 className="titley">
          Bring new clothing designs{" "}
          <span className="gradient-text">to life!</span>
        </h1>
        <Row
          style={{
            maxWidth: "550px",
            margin: "auto",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <Col>
            <div>
              <FaHandshake
                size={40}
                color="gray"
                // style={{
                //   cursor: "pointer",
                // }}
              />
            </div>
            <div
              style={{
                color: "gray",
                maxWidth: "200px",
                margin: "auto",
                // cursor: "pointer",
              }}
            >
              Pledge to buy clothing if it is produced
            </div>
            {/* <div>Pledge to buy designs if they are released</div> */}
          </Col>
          <Col>
            <div onClick={listOwnDesigns}>
              <GiPaintBrush
                style={{
                  cursor: "pointer",
                }}
                size={40}
                color="gray"
              />
            </div>
            <div
              style={{
                color: "gray",
                maxWidth: "200px",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={listOwnDesigns}
            >
              List your own designs by clicking{" "}
              <span style={{ fontWeight: "bold" }}>here</span>{" "}
            </div>
          </Col>
        </Row>
        {/* <p className='bannerSub'>{t('bannerSubtitle')}</p> */}
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
        }}
      >
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {filterType === "Discover" && (
              <FcBinoculars
                size={20}
                // color="gray"
                style={{
                  marginRight: "10px",
                }}
              />
              // <RiLightbulbFlashFill
              //   size={20}
              //   color="white"
              //   style={{
              //     marginRight: "10px",
              //     marginBottom: "5px",
              //   }}
              // />
            )}
            {filterType === "Successfully Funded" && (
              <FcShipped
                size={20}
                // color="gray"
                style={{
                  marginRight: "10px",
                }}
              />
            )}
            {filterType}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => setFilterType("Discover")}
              // href="#/action-1"
            >
              <FcBinoculars
                size={20}
                // color="gray"
                style={{
                  marginRight: "10px",
                }}
              />
              Discover
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setFilterType("Successfully Funded")}
              // href="#/action-2"
            >
              <FcShipped
                size={20}
                // color="gray"
                style={{
                  marginRight: "10px",
                }}
              />
              Succesfully Funded
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <h3>
          <span style={{ color: "#02af93" }}>Discover</span>
        </h3> */}
        {/* <h3>
          Discover{" "}
          <span style={{ color: "#02af93" }}>
            {" "}
            {posts?.length} potential designs{" "}
          </span>
        </h3> */}
      </div>
      {posts.length === 0 && (
        <div style={{ paddingBottom: "40px", paddingTop: "20px" }}>
          <Spinner
            as="span"
            animation="border"
            // size="sm"
            role="status"
            aria-hidden="true"
            style={{ marginLeft: "50%" }}
          />
        </div>
      )}
      {filterType === "Discover" && (
        <div className="listContainer">
          {posts.map((post) => (
            <PostCard
              // onClick={onCardClick}
              home={"t"}
              setClickedPost={setClickedPost}
              key={post.id}
              post={post}
            />
          ))}
        </div>
      )}
      {filterType === "Successfully Funded" && (
        <div className="listContainer">
          {fundedPosts.map((post) => (
            <PostCard
              // onClick={onCardClick}
              home={"t"}
              funded={"t"}
              setClickedPost={setClickedPost}
              key={post.id}
              post={post}
            />
          ))}
        </div>
      )}
      {/* </Container> */}
      <div className="whiteAngle" />
    </div>
  );
}

export default Home;
